import React from 'react';
  const icons = {
  "add-plus": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M2.01 8h12M8 14l.01-12"/></svg>,
"anchor-link": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M5 8h6M7 12H5c-2.21 0-4-1.79-4-4s1.79-4 4-4h2M9 12h2c2.21 0 4-1.79 4-4s-1.79-4-4-4H9" className="stroke-linejoin-round"/></svg>,
"angle-down": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m2 5 6 6 6-6" className="stroke-linejoin-round"/></svg>,
"angle-left-double": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M8 2 2 8l6 6" className="stroke-linejoin-round"/><path d="M13 2 7 8l6 6" className="stroke-linejoin-round"/></svg>,
"angle-left": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M11 2 5 8l6 6" className="stroke-linejoin-round"/></svg>,
"angle-right-double": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m8 2 6 6-6 6" className="stroke-linejoin-round"/><path d="m3 2 6 6-6 6" className="stroke-linejoin-round"/></svg>,
"angle-right": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m5 2 6 6-6 6" className="stroke-linejoin-round"/></svg>,
"angle-up": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m2 11 6-6 6 6" className="stroke-linejoin-round"/></svg>,
"arrow-down": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m3 9 5 5 5-5M8 14V1" className="stroke-linejoin-round"/></svg>,
"arrow-left": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M7 3 2 8l5 5M2 8h13" className="stroke-linejoin-round"/></svg>,
"arrow-right": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m9 3 5 5-5 5M14 8H1" className="stroke-linejoin-round"/></svg>,
"arrow-up": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M13 7 8 2 3 7M8 2v13" className="stroke-linejoin-round"/></svg>,
"audio-full": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M6.47 13.707 4 10.997H1v-6h3l2.47-2.71c.54-.59 1.53-.21 1.53.59v10.23c0 .8-.99 1.19-1.53.59v.01Z" className="stroke-linejoin-round"/><path d="M10 12.997c2.76 0 5-2.24 5-5s-2.24-5-5-5"/><path d="M10 9.997c1.1 0 2-.9 2-2s-.9-2-2-2"/></svg>,
"audio-half": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M7.47 13.41 5 10.7H2v-6h3l2.47-2.71C8.01 1.4 9 1.78 9 2.58v10.23c0 .8-.99 1.19-1.53.59v.01Z" className="stroke-linejoin-round"/><path d="M11 9.71c1.1 0 2-.9 2-2s-.9-2-2-2"/></svg>,
"audio-off": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M6.47 13.41 4 10.7H1v-6h3l2.47-2.71C7.01 1.4 8 1.78 8 2.58v10.23c0 .8-.99 1.19-1.53.59v.01ZM11 5.71l3.99 4M15 5.71 11 9.7" className="stroke-linejoin-round"/></svg>,
"backward-10-seconds": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M5.95 14.01h-.96c-.56 0-1.02-.46-1.02-1.02v-2.96c0-.56.45-1.01 1.01-1.02h.96c.56 0 1.02.45 1.02 1.02v2.96c0 .56-.46 1.02-1.02 1.02h.01Z" className="stroke-linejoin-round"/><path d="M1 9v5" className="stroke-linecap-square"/><path d="M1 0v5h5" className="stroke-linejoin-round"/><path d="M9.976 15A7.154 7.154 0 0 0 15 8.164C15 4.204 11.804 1 7.852 1A7.148 7.148 0 0 0 1.5 4.875" className="stroke-linejoin-round"/></svg>,
"bug": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M12 5.71a4 4 0 0 0-8 0v4a4 4 0 1 0 8 0v-4ZM15 8.71h-3M4 8.71H1M12 6H4M15 3.63l-3 2.08M15 13.78l-3-2.07M1 3.63l3 2.08M1 13.78l3-2.07"/></svg>,
"calendar": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M14 2H2v12h12V2Z" className="stroke-linejoin-round"/><path d="M4 6V4h2v2H4ZM7 6V4h2v2H7ZM10 6V4h2v2h-2ZM4 9V7h2v2H4ZM7 9V7h2v2H7ZM4 12v-2h2v2H4ZM7 12v-2h2v2H7ZM10 9V7h2v2h-2Z" className="filled no-stroke"/></svg>,
"call": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M13.99 7.083c0-2.76-2.24-5-5-5M11.07 10.002c-.6.58-1.55.61-2.14.02l-2.88-2.88a1.49 1.49 0 0 1 0-2.12c.52-.52.52-1.36 0-1.88l-.85-.85a.996.996 0 0 0-1.41 0l-.38.38a4.83 4.83 0 0 0 0 6.82l3.17 3.17a4.83 4.83 0 0 0 6.82 0l.38-.38a.996.996 0 0 0 0-1.41l-.85-.85c-.51-.51-1.34-.52-1.86-.02Z" className="stroke-linejoin-round"/></svg>,
"caret-down-filled": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m8 11 4-6H4l4 6Z" className="filled stroke-linejoin-round"/></svg>,
"caret-down": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m8 11 4-6H4l4 6Z" className="stroke-linejoin-round"/></svg>,
"caret-left-filled": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m5 8 6 4V4L5 8Z" className="filled stroke-linejoin-round"/></svg>,
"caret-right-filled": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m11 8-6 4V4l6 4Z" className="filled stroke-linejoin-round"/></svg>,
"caret-up-filled": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m8 5 4 6H4l4-6Z" className="filled stroke-linejoin-round"/></svg>,
"caret-up": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m8 5 4 6H4l4-6Z" className="stroke-linejoin-round"/></svg>,
"check": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m1 9 4 4L15 2" className="stroke-linejoin-round"/></svg>,
"close": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m2 1.71 12 12M2 13.71l12-12" className="stroke-linejoin-round"/></svg>,
"closed-caption-unavailable": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M15 1H1v14h14V1Z" className="stroke-linejoin-round"/><path d="M7.51 10H5c-.55 0-.99-.44-.99-.99V7c0-.55.44-.99.99-.99h1.01M13 10h-2.51c-.55 0-.99-.44-.99-.99V7c0-.55.44-.99.99-.99H13M1 1l14 14" className="stroke-linejoin-round"/></svg>,
"closed-caption": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M15 1H1v14h14V1Z" className="stroke-linejoin-round"/><path d="M7.51 10H5c-.55 0-.99-.44-.99-.99V7c0-.55.44-.99.99-.99h2.51M13 10h-2.51c-.55 0-.99-.44-.99-.99V7c0-.55.44-.99.99-.99H13" className="stroke-linejoin-round"/></svg>,
"command-prompt": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M15 1H1v14h14V1ZM12 11H9" className="stroke-linejoin-round"/><path d="M4.71 11.3 8 8 4.71 4.71" className="stroke-linejoin-round"/></svg>,
"contact": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M14 12c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1H2c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1h4v3l4-3h4Z" className="stroke-linejoin-round"/></svg>,
"copy": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M15 5H5v10h10V5Z" className="stroke-linejoin-round"/><path d="M13 1H1v11" className="stroke-linejoin-round"/></svg>,
"delete-marker": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M3 7V1h6l4 4v10h-3" className="stroke-linejoin-round"/><path d="M8 1v5h5M2 15l6-6M2 9.01l6.01 6" className="stroke-linejoin-round"/></svg>,
"download": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M1 15h14M13 6l-5 5-5-5M8 10V1" className="stroke-linejoin-round"/></svg>,
"drag-indicator": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><circle cx="5.5" cy="2.5" r="1.5" className="filled no-stroke"/><circle cx="5.5" cy="13.5" r="1.5" className="filled no-stroke"/><circle cx="5.5" cy="8" r="1.5" className="filled no-stroke"/><circle cx="10.5" cy="2.5" r="1.5" className="filled no-stroke"/><circle cx="10.5" cy="13.5" r="1.5" className="filled no-stroke"/><circle cx="10.5" cy="8" r="1.5" className="filled no-stroke"/></svg>,
"edit": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m6.19 13.275-4.19.7.7-4.19 7.2-7.2c.78-.78 2.05-.78 2.83 0l.66.66c.78.78.78 2.05 0 2.83l-7.2 7.2ZM9 3.995l3 3" className="stroke-linejoin-round"/></svg>,
"ellipsis": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><circle cx="8" cy="2.5" r="1.5" className="filled no-stroke"/><circle cx="8" cy="13.5" r="1.5" className="filled no-stroke"/><circle cx="8" cy="8" r="1.5" className="filled no-stroke"/></svg>,
"envelope": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m1 3 7 6 7-6" className="stroke-linejoin-round"/><path d="M15 3H1v10h14V3Z" className="stroke-linejoin-round"/></svg>,
"exit-full-screen": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M1 10.01h5V15M10 15v-5h5M6 1v5H1M15 6h-5V1" className="stroke-linejoin-round"/></svg>,
"expand": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M2 7V2h5M9 2h5v5M14 9v5H9M7 14H2V9M14 2 2 14M14 14 2.03 2.02" className="stroke-linejoin-round"/></svg>,
"external": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M14 8.01v-6H8M14.02 2 8 8.01M6 2.01H2v12h12v-3.99" className="stroke-linejoin-round"/></svg>,
"face-happy-filled": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M7.995 1.103a6.887 6.887 0 0 0-6.892 6.892 6.887 6.887 0 0 0 6.892 6.892 6.887 6.887 0 0 0 6.892-6.892 6.887 6.887 0 0 0-6.892-6.892Zm-2.002 7.61A2.148 2.148 0 0 0 8 10.134c.914 0 1.706-.592 2.007-1.423l1.646.596c-.54 1.489-1.966 2.577-3.653 2.577-1.686 0-3.114-1.088-3.653-2.577l1.646-.596ZM7.148 6.03a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0Zm3.95 0a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0Z" className="filled no-stroke"/><path d="M8 .5A7.495 7.495 0 0 0 .5 8c0 4.146 3.354 7.5 7.5 7.5s7.5-3.354 7.5-7.5S12.146.5 8 .5Zm0 1.559c3.61 0 6.008 2.566 6.008 5.941 0 2.404-2.044 6.014-6.008 6.014-3.818 0-6.01-2.9-6.01-6.014 0-2.603 1.712-5.941 6.01-5.941Z" className="filled no-stroke"/></svg>,
"face-happy": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><circle cx="8" cy="8" r="7"/><path d="M6 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM10.01 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" className="filled no-stroke"/><path d="M10.83 9.01c-.42 1.16-1.53 2-2.83 2s-2.41-.84-2.83-2" className="stroke-linejoin-round"/></svg>,
"face-neutral-filled": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M7.995 1.103a6.887 6.887 0 0 0-6.892 6.892 6.887 6.887 0 0 0 6.892 6.892 6.887 6.887 0 0 0 6.892-6.892 6.887 6.887 0 0 0-6.892-6.892ZM11 10.875H5v-1.75h6v1.75ZM7.148 6.03a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0Zm3.95 0a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0Z" className="filled no-stroke"/><path d="M8 .5A7.495 7.495 0 0 0 .5 8c0 4.146 3.354 7.5 7.5 7.5s7.5-3.354 7.5-7.5S12.146.5 8 .5Zm0 1.559c3.61 0 6.008 2.566 6.008 5.941 0 2.404-2.044 6.014-6.008 6.014-3.818 0-6.01-2.9-6.01-6.014 0-2.603 1.712-5.941 6.01-5.941Z" className="filled no-stroke"/></svg>,
"face-neutral": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><circle cx="8" cy="8" r="7"/><path d="M6 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM10.01 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" className="filled no-stroke"/><path d="M5 10h6" className="stroke-linejoin-round"/></svg>,
"face-sad-filled": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M7.995 1.103a6.887 6.887 0 0 0-6.892 6.892 6.887 6.887 0 0 0 6.892 6.892 6.887 6.887 0 0 0 6.892-6.892 6.887 6.887 0 0 0-6.892-6.892Zm-3.648 9.6C4.887 9.212 6.314 8.124 8 8.124c1.687 0 3.114 1.088 3.653 2.577l-1.646.596A2.148 2.148 0 0 0 8 9.875c-.913 0-1.706.592-2.007 1.423l-1.646-.596ZM7.148 6.03a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0Zm3.95 0a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0Z" className="filled no-stroke"/><path d="M8 .5A7.495 7.495 0 0 0 .5 8c0 4.146 3.354 7.5 7.5 7.5s7.5-3.354 7.5-7.5S12.146.5 8 .5Zm0 1.559c3.61 0 6.008 2.566 6.008 5.941 0 2.404-2.044 6.014-6.008 6.014-3.818 0-6.01-2.9-6.01-6.014 0-2.603 1.712-5.941 6.01-5.941Z" className="filled no-stroke"/></svg>,
"face-sad": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><circle cx="8" cy="8" r="7"/><path d="M6 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM10.01 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" className="filled no-stroke"/><path d="M10.83 11C10.41 9.84 9.3 9 8 9s-2.41.84-2.83 2" className="stroke-linejoin-round"/></svg>,
"file-open": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M13 15H3V1h6l4 4v10Z" className="stroke-linejoin-round"/><path d="M8 1v5h5M3 8l7 7" className="stroke-linejoin-round"/></svg>,
"file": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M13 15H3V1h6l4 4v10Z" className="stroke-linejoin-round"/><path d="M8 1v5h5" className="stroke-linejoin-round"/></svg>,
"filter": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m1 3 5 5v7l4-2V8l5-5V1H1v2Z" className="stroke-linejoin-round"/></svg>,
"flag": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M1.99 16V1M2 2.14c4 2.71 8-2.99 12-.28v7.28c-4-2.89-8 2.61-12-.28" className="stroke-linejoin-round"/></svg>,
"folder-open": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M6 14h8l-3-7H1l2 7h3Z" className="stroke-linejoin-round"/><path d="M2 7V2h6l1 2h5c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1h-1" className="stroke-linejoin-round"/></svg>,
"folder": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M15 5v9H2V2h6l1 2h5c.55 0 1 .45 1 1Z" className="stroke-linejoin-round"/></svg>,
"forward-10-seconds": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M13.95 14.01h-.96c-.56 0-1.02-.46-1.02-1.02v-2.96c0-.56.45-1.01 1.01-1.02h.96c.56 0 1.02.45 1.02 1.02v2.96c0 .56-.46 1.02-1.02 1.02h.01Z" className="stroke-linejoin-round"/><path d="M9 9v5" className="stroke-linecap-square"/><path d="M15 0v5h-5" className="stroke-linejoin-round"/><path d="M6.024 15A7.154 7.154 0 0 1 1 8.164C1 4.204 4.196 1 8.148 1A7.148 7.148 0 0 1 14.5 4.875" className="stroke-linejoin-round"/></svg>,
"full-screen": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M6 15H1v-5M15 10v5h-5M1 6V1h5M10 1h5v5" className="stroke-linejoin-round"/></svg>,
"gen-ai": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M6.15 10.365 8 15.005l1.86-4.64 4.64-1.86-4.64-1.85L8 2.005l-1.85 4.65-4.65 1.85 4.65 1.86Z" className="stroke-linejoin-round"/><path d="M2.38 4.915c.02.05.07.08.12.08.05 0 .12-.08.12-.08l.66-1.64 1.64-.66a.13.13 0 0 0 .08-.12c0-.05-.08-.12-.08-.12l-1.64-.66-.66-1.64c-.04-.1-.2-.1-.24 0l-.66 1.64-1.64.66a.13.13 0 0 0-.08.12c0 .05.08.12.08.12l1.64.66.66 1.64Z" className="filled no-stroke"/></svg>,
"globe": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><circle cx="8" cy="8" r="7"/><path d="M8 15c1.657 0 3-3.134 3-7S9.657 1 8 1 5 4.134 5 8s1.343 7 3 7ZM1 8h14" className="stroke-linejoin-round"/></svg>,
"grid-view": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M6 10H2v4h4v-4ZM14 10h-4v4h4v-4ZM6 2H2v4h4V2ZM14 2h-4v4h4V2Z" className="stroke-linejoin-round"/></svg>,
"group-active": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><circle cx="11" cy="7" r="3" className="filled no-stroke"/><circle cx="4.25" cy="4.25" r="2.75" className="filled no-stroke"/><path d="M6 14a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v2H6v-2Z" className="filled no-stroke"/><path d="M7.126 8H3a3 3 0 0 0-3 3v3h5a4 4 0 0 1 3.405-3.956A4 4 0 0 1 7.126 8Z" className="filled no-stroke"/></svg>,
"group": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M15 16v-2a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2"/><circle cx="11.25" cy="6.75" r="2.25"/><path d="M6.254 8.99H3a2.005 2.005 0 0 0-2.005 2.005v2h.01v-2C1.005 9.893 1.898 9 3 9h3.261a8.678 8.678 0 0 1-.007-.01Z" className="filled"/><circle cx="4.25" cy="4.25" r="2.25"/></svg>,
"heart-filled": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M13.49 3.546C12.124 2.31 10.312 2.689 9 4L8 5 7 4c-1.301-1.302-3.114-1.69-4.491-.454a3.409 3.409 0 0 0-.133 4.95L7.952 14s.067.028.086 0l5.576-5.505a3.409 3.409 0 0 0-.133-4.95h.01Z" className="filled stroke-linejoin-round"/></svg>,
"heart": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M13.752 2.873c-1.44-1.3-3.7-1.1-5.08.28l-.7.7-.7-.7c-1.37-1.37-3.63-1.58-5.08-.28a3.588 3.588 0 0 0-.14 5.21l5.87 5.87s.07.03.09 0l5.87-5.87a3.588 3.588 0 0 0-.14-5.21h.01Z" className="stroke-linejoin-round"/></svg>,
"insert-row": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M6 11H1V2h14v4M12 7v8M8 11h8" className="stroke-linejoin-round"/></svg>,
"key": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M10 1a5.002 5.002 0 0 0-4.6 6.96L1 12.36v2.65h4v-2h3v-2.42c.61.27 1.29.42 2 .42 2.76 0 5-2.24 5-5s-2.24-5-5-5V1Z" className="stroke-linejoin-round"/><path d="M10.5 7a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" className="filled no-stroke"/></svg>,
"keyboard": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M15 2H1v12h14V2ZM4 11h8M4 8h2M4 5h2M7 8h2M7 5h2M10 8h2M10 5h2"/></svg>,
"list-view": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M5 3h10M5 8h10M5 13h10"/><path d="M2.01 3.01H2V3h.01v.01ZM2.01 8.01H2V8h.01v.01ZM2.01 13.01H2V13h.01v.01Z" className="filled"/></svg>,
"location-pin": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M8 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" className="stroke-linejoin-round"/><path d="M12.01 9c.63-.83 1-1.87 1-3 0-2.76-2.24-5-5-5a5.002 5.002 0 0 0-4 8l4 6 4-6Z" className="stroke-linejoin-round"/></svg>,
"lock-private": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M12 7H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1ZM5 7V4c0-1.65 1.35-3 3-3s3 1.35 3 3v3" className="stroke-linejoin-round"/></svg>,
"map": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M2 12.8V2l3.61 1.21V14L2 12.8ZM10.4 12.8V2L14 3.05V14l-3.6-1.2ZM5.61 14l4.79-1.2M5.61 3.21 10.4 2" className="stroke-linejoin-round"/></svg>,
"menu": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M15 3H1M15 8H1M15 13H1" className="stroke-linejoin-round"/></svg>,
"microphone-off": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M3 15.01h10M8 15v-3"/><path d="M6.6 8.43c.36.36.86.59 1.41.59 1.1 0 2-.9 2-2v-2L6.6 8.43Z" className="filled"/><path d="M13 7.01c0 2.76-2.24 5-5 5-1.28 0-2.45-.48-3.33-1.28M3 12.01l10-10"/><path d="M8.01 1.01c-1.1 0-2 .9-2 2v1.67l3.23-3.23c-.34-.27-.77-.44-1.23-.44Z" className="filled"/><path d="M3.01 7.01c0 .482-.162 1.263.33 1.75"/></svg>,
"microphone": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M3 15h10M8 15v-3M10 3a2 2 0 1 0-4 0v4a2 2 0 1 0 4 0V3Z" className="stroke-linejoin-round"/><path d="M13 7c0 2.76-2.24 5-5 5S3 9.76 3 7" className="stroke-linejoin-round"/></svg>,
"mini-player": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M14 10.01H8v4h6v-4Z" className="stroke-linejoin-round"/><path d="M5 14H2V2h12v5" className="stroke-linejoin-round"/></svg>,
"multiscreen": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M15 5H5v7h10V5Z" className="stroke-linejoin-round"/><path d="M11 3V1.01L1.01 1 1 8h1.998M10 12v3M7 15h6" className="stroke-linejoin-round"/></svg>,
"notification": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M14 12H2c-.39 0-.63-.44-.41-.76L4 8V5c0-2.21 1.79-4 4-4s4 1.79 4 4v3l2.41 3.24c.22.33-.02.76-.41.76ZM6 13c0 1.1.9 2 2 2s2-.9 2-2" className="stroke-linejoin-round"/></svg>,
"pause": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M5 2v12M11 2v12" className="stroke-linejoin-round"/></svg>,
"play": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m4 13.03 8-5-8-5v10Z" className="stroke-linejoin-round filled"/></svg>,
"redo": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m11 2 4 4-4 4" className="stroke-linejoin-round"/><path d="M14 6H5.5C3.01 6 1 8.01 1 10.5S3.01 15 5.5 15H8" className="stroke-linejoin-round"/></svg>,
"refresh": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M15 0v5l-5-.04" className="stroke-linejoin-round"/><path d="M15 8c0 3.87-3.13 7-7 7s-7-3.13-7-7 3.13-7 7-7c2.79 0 5.2 1.63 6.33 4"/></svg>,
"remove": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M1 5h14M13 5l-1 10H4L3 5M5 5V2h6v3" className="stroke-linejoin-round"/></svg>,
"resize-area": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M14 4 4 14M10 14l4-4" className="stroke-linejoin-round"/></svg>,
"script": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M5 4 1 8l4 4M11 4l4 4-4 4" className="stroke-linejoin-round"/><path d="M7 13 9 3"/></svg>,
"search": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m11 11 4 4M7 12A5 5 0 1 0 7 2a5 5 0 0 0 0 10Z" className="stroke-linejoin-round"/></svg>,
"security": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M8 1C6.29 2.53 4.13 3.32 2 3.48v3.48c0 2.05.76 3.88 1.71 5.14.92 1.22 2.32 2.21 4.29 2.9 1.97-.69 3.37-1.68 4.29-2.9A8.684 8.684 0 0 0 14 6.96V3.48C11.87 3.32 9.71 2.52 8 1Z" className="stroke-linejoin-round"/><path d="M8 4v5M8 10v2"/></svg>,
"send": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m1 1 14 6.92L1 15l3-7-3-7ZM4 8h11" className="stroke-linejoin-round"/></svg>,
"settings": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M6.11 1.729c.07-.42.44-.729.86-.729h2.02c.43 0 .79.31.86.729l.17.999c.05.29.24.529.5.679.06.03.11.06.17.1.25.15.56.2.84.1l.95-.35c.4-.15.85 0 1.07.38l1.01 1.747c.21.37.13.839-.2 1.108l-.78.64c-.23.189-.34.479-.33.768v.2c0 .29.11.579.33.769l.78.639c.33.27.42.739.2 1.108l-1.01 1.748c-.21.37-.66.529-1.06.38l-.95-.35a.966.966 0 0 0-.84.1c-.06.03-.11.07-.17.1-.26.14-.45.389-.5.679l-.17.998A.878.878 0 0 1 9 15H6.98a.87.87 0 0 1-.86-.729l-.17-.998a.988.988 0 0 0-.5-.68c-.06-.03-.11-.06-.17-.1a.996.996 0 0 0-.84-.1l-.95.35c-.4.15-.85 0-1.06-.38l-1.01-1.747a.873.873 0 0 1 .2-1.108l.78-.64c.23-.189.34-.479.33-.768v-.2c0-.3-.11-.579-.33-.769l-.78-.639a.861.861 0 0 1-.2-1.108l1.01-1.748c.21-.37.66-.529 1.07-.38l.95.35c.28.1.58.06.84-.1.06-.03.11-.07.17-.1.26-.14.45-.379.5-.678l.15-1Z" className="stroke-linecap-round stroke-linejoin-round"/><path d="M10 8c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2Z" className="stroke-linecap-round stroke-linejoin-round"/></svg>,
"share": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M3.5 11a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM12.5 6a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM12.5 15a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM10.326 4.7 5.678 7.293M10.223 11.483l-4.448-1.96"/></svg>,
"shrink": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M10 1v5h5M10.71 5.29 15 1M1 10h5v5M5.3 10.7 1 15" className="stroke-linejoin-round"/></svg>,
"star-filled": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m8 1 2.16 4.38 4.84.71-3.5 3.41.83 4.81L8 12.04l-4.33 2.27.83-4.81L1 6.09l4.84-.71L8 1Z" className="filled stroke-linejoin-round"/></svg>,
"star-half": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M8 1v11.04l-4.33 2.27.83-4.81L1 6.09l4.84-.71L8 1Z" className="filled no-stroke"/><path d="m8 1 2.16 4.38 4.84.71-3.5 3.41.83 4.81L8 12.04l-4.33 2.27.83-4.81L1 6.09l4.84-.71L8 1Z" className="stroke-linejoin-round"/></svg>,
"star": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m8 1 2.16 4.38 4.84.71-3.5 3.41.83 4.81L8 12.04l-4.33 2.27.83-4.81L1 6.09l4.84-.71L8 1Z" className="stroke-linejoin-round"/></svg>,
"status-in-progress": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><circle cx="8" cy="8" r="7"/><path d="M9 7H7v2h2V7ZM6 7H4v2h2V7ZM12 7h-2v2h2V7Z" className="filled no-stroke"/></svg>,
"status-info": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><circle cx="8" cy="8" r="7"/><path d="M8 12V7M8 6V4"/></svg>,
"status-negative": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><circle cx="8" cy="8" r="7"/><path d="m5.5 5.5 5 5M10.5 5.5l-5 5"/></svg>,
"status-pending": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><circle cx="8" cy="8" r="7"/><path d="M8 4v5H4" className="stroke-linejoin-round"/></svg>,
"status-positive": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><circle cx="8" cy="8" r="7"/><path d="M4.5 7.5 7 10l4-5" className="stroke-linejoin-round"/></svg>,
"status-stopped": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><circle cx="8" cy="8" r="7"/><path d="M5 8h6"/></svg>,
"status-warning": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M8 5v4M8 10v2M6.52 1.88l-5.33 9.76c-.13.23-.19.5-.19.76 0 .88.71 1.59 1.59 1.59H13.4c.88 0 1.59-.71 1.59-1.59 0-.27-.07-.53-.19-.76L9.48 1.88C9.18 1.34 8.62 1 8 1s-1.18.34-1.48.88Z" className="stroke-linejoin-round"/></svg>,
"subtract-minus": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M2 8h12" className="stroke-linejoin-round"/></svg>,
"suggestions": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M8.12 15h-2l-.99-5.02C3.92 9.07 3.12 7.63 3.12 6c0-2.76 2.24-5 5-5s5 2.24 5 5c0 1.63-.8 3.07-2.01 3.98L10.12 15h-2ZM11.12 12h-6" className="stroke-linejoin-round"/></svg>,
"support": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><circle cx="8" cy="8" r="7"/><path d="M5.75 6.338c.13-1.178.811-2.339 2.37-2.339 1.472 0 2.435 1.312 2.042 2.468-.215.633-.916 1.132-1.385 1.578C8.162 8.631 8 9.2 8 10"/><path d="M8 12.01h.01V12H8v.01Z" className="filled"/></svg>,
"thumbs-down-filled": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M1 1h2.01v9H1c-.55 0-1-.45-1-1V2c0-.55.45-1 1-1Zm14.14 6.22-1.69-5.03C13.21 1.48 12.54 1 11.79 1H4.01v9l3.23 3.88c.6.72 1.77.29 1.77-.64V9h4.86c.92 0 1.57-.91 1.28-1.78h-.01Z" className="filled no-stroke"/></svg>,
"thumbs-down": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M9 13.13V9h4.12c.6 0 1.02-.59.83-1.16l-1.6-4.77A1.58 1.58 0 0 0 10.86 2H1v8h4l2.41 3.61c.48.72 1.59.38 1.59-.48ZM5 2v8" className="stroke-linejoin-round"/></svg>,
"thumbs-up-filled": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M3 15.001H1c-.55 0-1-.45-1-1v-7c0-.55.45-1 1-1h2v9Zm10.86-8H9v-4.24c0-.93-1.17-1.36-1.77-.64L4 6.001v9h7.78c.75 0 1.42-.48 1.66-1.19l1.69-5.03c.29-.87-.36-1.78-1.28-1.78h.01Z" className="filled no-stroke"/></svg>,
"thumbs-up": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M9 2.871v4.13h4.12c.6 0 1.02.59.83 1.16l-1.6 4.77a1.58 1.58 0 0 1-1.49 1.07H1v-8h4l2.41-3.61c.48-.72 1.59-.38 1.59.48ZM5 14.001v-8" className="stroke-linejoin-round"/></svg>,
"ticket": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M13 8c0-1.1.9-2 2-2V3H1v3c1.1 0 2 .9 2 2s-.9 2-2 2v3h14v-3c-1.1 0-2-.9-2-2Z" className="stroke-linejoin-round"/></svg>,
"transcript": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M15 1H1v14h14V1ZM9 10H4M12 6H4" className="stroke-linejoin-round"/></svg>,
"treeview-collapse": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M14 2H2v12h12V2ZM5 8h6" className="stroke-linejoin-round"/></svg>,
"treeview-expand": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M14 2H2v12h12V2ZM8 5v6M5 8h6" className="stroke-linejoin-round"/></svg>,
"undo": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M5 2 1 6l4 4" className="stroke-linejoin-round"/><path d="M2 6h8.5c2.49 0 4.5 2.01 4.5 4.5S12.99 15 10.5 15H8" className="stroke-linejoin-round"/></svg>,
"unlocked": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M11 7H3a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1ZM9 7V4c0-1.65 1.35-3 3-3s3 1.35 3 3" className="stroke-linejoin-round"/></svg>,
"upload-download": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m7 7-3 3-3-3M4 9V1M15 9l-3-3-3 3M12 7v8M0 14h8M8 2h8" className="stroke-linejoin-round"/></svg>,
"upload": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M1 1h14M13 10 8 5l-5 5M8 6v9" className="stroke-linejoin-round"/></svg>,
"user-profile-active": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M8 6a2.5 2.5 0 0 0 0-5 2.5 2.5 0 0 0 0 5ZM2 15.5v-3c0-1.66 1.34-3 3-3h6c1.66 0 3 1.34 3 3v3" className="filled"/><path d="M2 15.5v-3c0-1.66 1.34-3 3-3h6c1.66 0 3 1.34 3 3v3" className="stroke-linejoin-round"/></svg>,
"user-profile": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M8 7c1.66 0 3-1.34 3-3S9.66 1 8 1 5 2.34 5 4s1.34 3 3 3Z"/><path d="M2 16v-3c0-1.66 1.34-3 3-3h6c1.66 0 3 1.34 3 3v3" className="stroke-linejoin-round"/></svg>,
"video-camera-off": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M11 6V3H1v9.97h10V9.98L15 13V3.04L11 6ZM4 6l4 4M8 6.01 4 10" className="stroke-linejoin-round"/></svg>,
"video-camera-on": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M11 6V3H1v9.97L11 13v-3l4 3V3.04L11 6Z" className="stroke-linejoin-round"/></svg>,
"video-camera-unavailable": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M11 5.99V3H1v9.97h10V9.98L15 13V3.04l-4 2.95ZM4 8h4" className="stroke-linejoin-round"/></svg>,
"video-off": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><circle cx="8" cy="7" r="5"/><path d="M3 15h10M8 15v-3M6.01 5.01 10 9M10 5 6 9" className="stroke-linejoin-round"/></svg>,
"video-on": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><circle cx="8" cy="7" r="5"/><circle cx="8" cy="7" r="2" className="filled no-stroke"/><path d="M3 15h10M8 15v-3"/></svg>,
"video-unavailable": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><circle cx="8" cy="7" r="5"/><path d="M3 15h10M6 7h4M8 15v-3"/></svg>,
"view-full": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M15 1H1v14h14V1Z" className="stroke-linejoin-round"/><path d="M11.5 4h-7a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5Z" className="filled no-stroke"/></svg>,
"view-horizontal": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M15 15V1H1v14h14Z" className="stroke-linejoin-round"/><path d="M11.5 7h-7a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-.5-.5Z" className="filled no-stroke"/></svg>,
"view-vertical": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M15 1H1v14h14V1Z" className="stroke-linejoin-round"/><path d="M11.5 4h-4a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5Z" className="filled no-stroke"/></svg>,
"zoom-in": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><circle cx="7" cy="7" r="6"/><path d="m11 11 4 4M7 4v6M4 7l6 .01"/></svg>,
"zoom-out": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="m11 11 4 4"/><circle cx="7" cy="7" r="6"/><path d="m4 7 6 .01"/></svg>,
"zoom-to-fit": <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M11 5H5v6h6V5ZM6 1H1v5M10 1h5v5M6 15H1v-5M10 15h5v-5" className="stroke-linejoin-round"/></svg>
  };
  export default icons;